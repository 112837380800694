/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AboutSection,
  ContactSection,
  Footer,
  GallerySection,
  MakingOfSection,
  YarnsSection,
} from "@/models";
import { BImage } from "@/models/Image";
import imageLoader from "@/utils/imageLoader";

class MainPage {
  public landingPhoto: BImage;
  public readonly fallbackPhoto: BImage;
  public readonly aboutSection: AboutSection;
  public readonly gallerySection: GallerySection;
  public readonly makingOfSection: MakingOfSection;
  public readonly yarnsSection: YarnsSection;
  public readonly contactSection: ContactSection;
  public readonly footer: Footer;

  constructor(json: any) {
    this.landingPhoto = this.formatLandingPhoto(json.landingPhoto.photo);
    this.fallbackPhoto = this.formatLandingPhoto(
      json.landingPhoto.fallbackPhoto
    );
    this.aboutSection = new AboutSection(json.aboutSection);
    this.gallerySection = new GallerySection(json.gallerySection);
    this.makingOfSection = new MakingOfSection(json.makingOfSection);
    this.yarnsSection = new YarnsSection(json.yarnsSection);
    this.contactSection = new ContactSection(json.contactSection);
    this.footer = new Footer(json.footer);
  }

  async loadImages(): Promise<any> {
    // Execute these async
    this.yarnsSection.loadCoverPhoto();
    this.gallerySection.loadCoverPhoto();
    this.contactSection.loadCoverPhoto();
    this.makingOfSection.loadCoverPhoto();

    await Promise.allSettled([
      imageLoader(this.landingPhoto.url).catch(() => {
        this.landingPhoto = this.fallbackPhoto;
        return imageLoader(this.fallbackPhoto.url);
      }),
      this.aboutSection.loadCoverPhoto(),
    ]);
  }

  formatLandingPhoto(json: any): BImage {
    const screenWidth = window.innerWidth || screen.width;
    const screenHeight = window.innerHeight || screen.height;
    const imageWidth =
      screenWidth > screenHeight ? screenWidth : screenWidth * 1.8;
    return new BImage(json, undefined, imageWidth);
  }
}

export default MainPage;
