<template>
  <div
    v-if="loadingImages || !animationFinished"
    class="absolute top-0 z-50 left-0 w-full grid place-items-center h-screen"
  >
    <img src="/loading.gif" width="150" />
  </div>
  <div v-else>
    <BNavbar></BNavbar>
    <router-view />
  </div>
</template>

<script>
import { defineComponent, ref, provide, toRef } from "vue";
import { BNavbar } from "@/components";
import { useStore } from "vuex";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../tailwind.config.js";

export default defineComponent({
  components: {
    BNavbar,
  },
  setup() {
    const fullConfig = resolveConfig(tailwindConfig);
    provide("tailwindConfig", fullConfig);
    const store = useStore();
    const loadingData = toRef(store.state, "loadingData");
    const loadingImages = toRef(store.state, "loadingImages");

    // Add a loading timeout for the animation to finish
    const animationFinished = ref(false);
    setTimeout(() => {
      animationFinished.value = true;
    }, 1400);

    store.dispatch("fetchData");
    return {
      loadingImages,
      loadingData,
      animationFinished,
    };
  },
});
</script>
